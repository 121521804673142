import React from "react";
import { cn } from "@yardzen/next-client-util";

export const H2: React.FC<React.HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...props
}) => (
  <h2
    className={cn(
      "font-display scroll-m-20 border-b pb-2 text-3xl tracking-tight first:mt-0",
      className,
    )}
    {...props}
  >
    {children}
  </h2>
);
